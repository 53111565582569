.main {
  width: 100%;
  height: 100%;

}

.head {
  width: 100%;
  background-color: #515d1e;
  height: 40px;
  display: flex;
}

.head2 {
  width: 50%;
  height: 100%;

  margin-left: 0px;
}

.head2 p {
  color: white;
  margin-left: 20px;
  padding-top: 04px;
}


.navbar-brand,
.navbar-nav .nav-link {
  color: #0a2634;
  font-size: 20px;
  padding-left: 0px;
  font-weight: bold;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;
}

.navigation{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
}


.appoint {
  color: white;
  border-radius: 16px;
  margin-left: 0px;
  background-color: #515d1e;
  text-align: center;
}

.header {
  height: 460px;
  width: 100%;
}


.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slider img {
  width: 100%;
  height: 460px;
  transition: opacity 0.5s ease-in-out;
  /* Add a smooth transition effect */
}

.sect {
  display: flex;
  width: 100%;
  height: 550px;
  margin-top: 10px;
  background-image: url(Img/backs.jpg);
  background-repeat: no-repeat;
  background-size: cover;

}

.sect1 {
  width: 40%;
  height: 550px;
}

.profile {
  width: 25rem;
  margin-left: 50px;
  margin-top: 20px;
}


.sect2 {
  width: 60%;
  height: 550px;

}

.sect2 h1 {
  margin-top: 50px;
  margin-left: 100px;
}

.sect2 p {
  margin-top: 30px;
  font-size: 22px;
  font-family: 'Times New Roman', Times, serif;
  color: black;

}


.derivate {
  width: 100%;
  height: 450px;
  background-color: #0a2634;
  margin-top: 10px;
}

.derivate h1 {
  text-align: center;
  color: white;
  padding-top: 100px;
  font-size: 4rem;
}

.derivate p {
  color: white;
  text-align: center;
  padding-top: 50px;
  font-size: 2rem;
}

.derivate button {
  margin-left: 42%;
  margin-top: 40px;
  height: 40px;
  width: 200px;
  background-color: white;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

.derivate button:hover {
  background-color: black;
  color: white;
}

.depart {
  width: 100%;
  height: 400px;
  background-color: #0a2634;
  margin-top: 05px;
  display: flex;
}

.depart2 {
  width: 22%;
  height: 350px;
  background-color: #0a2634;
  margin-top: 20px;
  border: 1px solid white;
  margin-left: 26px;
  border-radius: 10px;
  color: white;
}

.depart2 h1 {
  font-size: 24px;
  text-align: center;
  padding-top: 15px;

}

.depart2 img {
  width: 98%;
  padding-left: 04px;
  padding-top: 20px;
  height: 220px;
}


.social {
  width: 100%;
  height: 60px;
  background-color: lightgray;
  margin-top: 100px;
  display: flex;

}

.socil2 {
  width: 50%;
  height: 100%;
  display: flex;
}

.socil2 h1 {
  font-size: 30px;
  padding-top: 10px;
  padding-left: 100px;
  color: #515d1e;
}


.socil2 a {
  margin-left: 30px;
  margin-top: 10px;
  background-color: #515d1e;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  padding-top: 04px;
  font-size: 20px;
  color: white;
}




.footer {
  width: 100%;
  height: 400px;
  background-color: lightgray;
  display: flex;

}

.foot {
  width: 25%;
  height: 100%;

}

.foot h1 {
  font-size: 27px;
  padding-top: 20px;
  text-align: center;
}

.link {
  margin-left: 100px;
  color: black;
  text-decoration: none;
  font-size: 17px;
  margin-top: 20px;
}

.foot p {
  margin-left: 20px;
  margin-top: 35px;
}


.react-multi-carousel {
  margin-top: 100px;
}

/* Add your global styles here */

/* Styles for the carousel container */
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 10px;
  /* Adjust the margin as needed */
}

/* Styles for the images inside the carousel */
.react-multi-carousel-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  /* Add border-radius for rounded corners */
}

/* Styles for the carousel navigation buttons (optional) */
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
  display: flex;
}

.react-multi-carousel-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  /* Change the color of the navigation dots */
  cursor: pointer;

}

.react-multi-carousel-dot--active {
  background-color: #333;
  /* Change the color of the active navigation dot */
}

.contact {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input,
textarea {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
  /* Add margin as needed */
}

.gallery img {
  width: 100%;
  /* Make images fill the container width */
  height: auto;
  /* Maintain aspect ratio */
  max-width: 300px;
  /* Limit maximum width if needed */
  margin: 10px;
  /* Add margin as needed */
}


.icon-bar {
  position: fixed;
  top: 80%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}



.divider {
  font-size: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;

  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.divider::before,
.divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: black;
  margin: 5px;

}




.icon-bar2 {
  position: fixed;

  top: 40%;
  margin-left: 95%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar2 a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 26px;

}

.icon-bar2 a:hover {
  background-color: #000;
}

.whatsapp {
  background: #25D366;
  color: white;

}

.twitter2 {
  background: #55ACEE;
  color: white;
}

.youtube2 {
  background: #bb0000;
  color: white;
}

.cronic{
  width: 100%;
  height: 450px;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.cronic p {
  margin-top: 70px;
  font-size: 18px;
  color: #0a2634;
}















@media screen and (max-width: 600px) {
  .main {
    width: 100%;
    height: 100%;

  }

  .head {
    width: 100%;
    background-color: #515d1e;
    height: 100px;
    display: flex;
    flex-direction: column;
  }

  .head2 {
    width: 100%;
    height: 50px;
    margin-left: 0px;
  }

  .head2 p {
    color: white;
    margin-left: 10px;
    padding-top: 04px;
    font-size: 16px;
  }


  .navbar-brand,
  .navbar-nav .nav-link {
    color: #0a2634;
    font-size: 20px;
    padding-left: 0px;
    font-weight: bold;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;
  }

  .appoint {
    color: white;
    border-radius: 16px;
    margin-left: 0px;
    background-color: #515d1e;
    text-align: center;
  }

  .header {
    height: 240px;
    width: 100%;
  }


  .slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .slider img {
    width: 100%;
    height: 230px;
    transition: opacity 0.5s ease-in-out;
    /* Add a smooth transition effect */
  }

  .sect {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    background-color: white;
    background: none;

  }

  .sect1 {
    width: 100%;
    height: 550px;
  }

  .profile {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }


  .sect2 {
    width: 100%;
    height: 100%;
    color: #000;
  }

  .sect2 h1 {
    margin-top: 20px;
    margin-left: 100px;
  }

  .sect2 p {
    margin-top: 60px;
    font-size: 17px;
    color: black;
    margin-left: 10px;

  }


  .derivate {
    width: 100%;
    height: 450px;
    background-color: #0a2634;
    margin-top: 10px;
  }

  .derivate h1 {
    text-align: center;
    color: white;
    padding-top: 50px;
    font-size: 1.8rem;
  }

  .derivate p {
    color: white;
    text-align: center;
    padding-top: 50px;
    font-size: 1.5rem;
  }

  .derivate button {
    margin-left: 20%;
    margin-top: 40px;
    height: 40px;
    width: 200px;
    background-color: white;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 18px;
    border-radius: 10px;
  }

  .derivate button:hover {
    background-color: black;
    color: white;
  }

  .depart {
    width: 100%;
    height: 100%;
    background-color: #0a2634;
    margin-top: 05px;
    display: flex;
    flex-direction: column;
  }

  .depart2 {
    width: 95%;
    height: 350px;
    background-color: #0a2634;
    margin-top: 20px;
    border: 1px solid white;
    margin-left: 05px;
    border-radius: 10px;
    color: white;
  }

  .depart2 h1 {
    font-size: 24px;
    text-align: center;
    padding-top: 15px;

  }

  .depart2 img {
    width: 98%;
    padding-left: 04px;
    padding-top: 20px;
    height: 220px;
  }


  .social {
    width: 100%;
    height: 100%;
    background-color: lightgray;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
  }

  .socil2 {
    width: 100%;
    height: 60px;
    display: flex;
  }

  .socil2 h1 {
    font-size: 20px;
    padding-top: 10px;
    padding-left: 20px;
    color: #515d1e;
  }


  .socil2 a {
    margin-left: 20px;
    margin-top: 10px;
    background-color: #515d1e;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    padding-top: 04px;
    font-size: 20px;
    color: white;
  }




  .footer {
    width: 100%;
    height: 100%;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
  }

  .foot {
    width: 100%;
    height: 100%;

  }

  .foot h1 {
    font-size: 27px;
    padding-top: 20px;
    text-align: center;
  }

  .link {
    margin-left: 100px;
    color: black;
    text-decoration: none;
    font-size: 19px;
    margin-top: 20px;
  }

  .foot p {
    margin-left: 20px;
    margin-top: 35px;
  }



  .react-multi-carousel {
    margin-top: 100px;
  }

  /* Add your global styles here */

  /* Styles for the carousel container */
  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 10px;
    /* Adjust the margin as needed */
  }

  /* Styles for the images inside the carousel */
  .react-multi-carousel-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    /* Add border-radius for rounded corners */
  }

  /* Styles for the carousel navigation buttons (optional) */
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    padding: 0;
    display: flex;
  }

  .react-multi-carousel-dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    /* Change the color of the navigation dots */
    cursor: pointer;

  }

  .react-multi-carousel-dot--active {
    background-color: #333;
    /* Change the color of the active navigation dot */
  }

  .contact {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 8px;
  }

  input,
  textarea {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }


  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
    /* Add margin as needed */
  }

  .gallery img {
    width: 100%;
    /* Make images fill the container width */
    height: auto;
    /* Maintain aspect ratio */
    max-width: 300px;
    /* Limit maximum width if needed */
    margin: 10px;
    /* Add margin as needed */
  }


  .icon-bar {
    position: fixed;
    top: 80%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {
    background: #3B5998;
    color: white;
  }

  .twitter {
    background: #55ACEE;
    color: white;
  }

  .youtube {
    background: #bb0000;
    color: white;
  }

  .content {
    margin-left: 75px;
    font-size: 30px;
  }



  .divider {
    font-size: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;

    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }

  .divider::before,
  .divider::after {
    flex: 1;
    content: '';
    padding: 1px;
    background-color: black;
    margin: 5px;

  }




  .icon-bar2 {
    position: fixed;
    top: 45%;
    margin-left: 84%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .icon-bar2 a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 26px;
  }

  .icon-bar2 a:hover {
    background-color: #000;
  }

  .whatsapp {
    background: #25D366;
    color: white;

  }

  .twitter2 {
    background: #55ACEE;
    color: white;
  }

  .youtube2 {
    background: #bb0000;
    color: white;
  }



}



@media screen and (min-width: 601px) and (max-width: 1250px) {
  .main {
    width: 1250px;
    height: 100%;

  }

  .head {
    width: 100%;
    background-color: #515d1e;
    height: 40px;
    display: flex;
  }

  .head2 {
    width: 50%;
    height: 100%;

    margin-left: 0px;
  }

  .head2 p {
    color: white;
    margin-left: 20px;
    padding-top: 04px;
  }


  .navbar-brand,
  .navbar-nav .nav-link {
    color: #0a2634;
    font-size: 20px;
    padding-left: 0px;
    font-weight: bold;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;
  }

  .appoint {
    color: white;
    border-radius: 16px;
    margin-left: 0px;
    background-color: #515d1e;
    text-align: center;
  }

  .header {
    height: 460px;
    width: 100%;
  }


  .slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .slider img {
    width: 100%;
    height: 460px;
    transition: opacity 0.5s ease-in-out;
    /* Add a smooth transition effect */
  }

  .sect {
    display: flex;
    width: 100%;
    height: 550px;
    margin-top: 10px;
    background-image: url(Img/backs.jpg);
    background-repeat: no-repeat;
    background-size: cover;

  }

  .sect1 {
    width: 40%;
    height: 550px;
  }

  .profile {
    width: 25rem;
    margin-left: 50px;
    margin-top: 20px;
  }


  .sect2 {
    width: 60%;
    height: 550px;

  }

  .sect2 h1 {
    margin-top: 50px;
    margin-left: 100px;
  }

  .sect2 p {
    margin-top: 60px;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    color: black;

  }


  .derivate {
    width: 100%;
    height: 450px;
    background-color: #0a2634;
    margin-top: 10px;
  }

  .derivate h1 {
    text-align: center;
    color: white;
    padding-top: 100px;
    font-size: 4rem;
  }

  .derivate p {
    color: white;
    text-align: center;
    padding-top: 50px;
    font-size: 2rem;
  }

  .derivate button {
    margin-left: 42%;
    margin-top: 40px;
    height: 40px;
    width: 200px;
    background-color: white;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 18px;
    border-radius: 10px;
  }

  .derivate button:hover {
    background-color: black;
    color: white;
  }

  .depart {
    width: 100%;
    height: 400px;
    background-color: #0a2634;
    margin-top: 05px;
    display: flex;
  }

  .depart2 {
    width: 22%;
    height: 350px;
    background-color: #0a2634;
    margin-top: 20px;
    border: 1px solid white;
    margin-left: 26px;
    border-radius: 10px;
    color: white;
  }

  .depart2 h1 {
    font-size: 24px;
    text-align: center;
    padding-top: 15px;

  }

  .depart2 img {
    width: 98%;
    padding-left: 04px;
    padding-top: 20px;
    height: 220px;
  }


  .social {
    width: 100%;
    height: 60px;
    background-color: lightgray;
    margin-top: 100px;
    display: flex;

  }

  .socil2 {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .socil2 h1 {
    font-size: 30px;
    padding-top: 10px;
    padding-left: 100px;
    color: #515d1e;
  }


  .socil2 a {
    margin-left: 30px;
    margin-top: 10px;
    background-color: #515d1e;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    padding-top: 04px;
    font-size: 20px;
    color: white;
  }




  .footer {
    width: 100%;
    height: 400px;
    background-color: lightgray;
    display: flex;

  }

  .foot {
    width: 25%;
    height: 100%;

  }

  .foot h1 {
    font-size: 27px;
    padding-top: 20px;
    text-align: center;
  }

  .link {
    margin-left: 100px;
    color: black;
    text-decoration: none;
    font-size: 19px;
    margin-top: 20px;
  }

  .foot p {
    margin-left: 20px;
    margin-top: 35px;
  }


  .react-multi-carousel {
    margin-top: 100px;
  }

  /* Add your global styles here */

  /* Styles for the carousel container */
  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 10px;
    /* Adjust the margin as needed */
  }

  /* Styles for the images inside the carousel */
  .react-multi-carousel-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    /* Add border-radius for rounded corners */
  }

  /* Styles for the carousel navigation buttons (optional) */
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    padding: 0;
    display: flex;
  }

  .react-multi-carousel-dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    /* Change the color of the navigation dots */
    cursor: pointer;

  }

  .react-multi-carousel-dot--active {
    background-color: #333;
    /* Change the color of the active navigation dot */
  }

  .contact {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 8px;
  }

  input,
  textarea {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }


  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
    /* Add margin as needed */
  }

  .gallery img {
    width: 100%;
    /* Make images fill the container width */
    height: auto;
    /* Maintain aspect ratio */
    max-width: 300px;
    /* Limit maximum width if needed */
    margin: 10px;
    /* Add margin as needed */
  }


  .icon-bar {
    position: fixed;
    top: 80%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {
    background: #3B5998;
    color: white;
  }

  .twitter {
    background: #55ACEE;
    color: white;
  }

  .youtube {
    background: #bb0000;
    color: white;
  }

  .content {
    margin-left: 75px;
    font-size: 30px;
  }



  .divider {
    font-size: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;

    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }

  .divider::before,
  .divider::after {
    flex: 1;
    content: '';
    padding: 1px;
    background-color: black;
    margin: 5px;

  }




  .icon-bar2 {
    position: fixed;

    top: 40%;
    margin-left: 95%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .icon-bar2 a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 26px;

  }

  .icon-bar2 a:hover {
    background-color: #000;
  }

  .whatsapp {
    background: #25D366;
    color: white;

  }

  .twitter2 {
    background: #55ACEE;
    color: white;
  }

  .youtube2 {
    background: #bb0000;
    color: white;
  }

}